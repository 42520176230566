import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"


const Gdpr = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Acord privind Prelucrarea Datelor Clienților, conform GDPR"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Acord privind Prelucrarea Datelor Clienților, conform GDPR"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-policy-content">
                <p><em>Versiunea 1.0, actualizat la 24 mai 2018</em></p>
                <p>Prezentul Acord privind Prelucrarea Datelor Clienților (DPA) reflectă cerințele Regulamentului
                  European privind Protecția Datelor (“GDPR”), care va intra în vigoare la data de 25 mai 2018.
                  Produsele și serviciile Sitebunker oferite în cadrul Uniunii Europene sunt conforme cu GDPR, iar
                  prezentul DPA vă oferă documentația necesară privind această conformitate.</p>
                <p>Prezentul Acord privind Prelucrarea Datelor (“DPA”) este un act adițional la Termenii de prestare a
                  serviciilor (“Acord”) între Exim Host SRL (“Sitebunker”) și Client. Toți termenii scriși cu majuscule,
                  care nu sunt definiți în prezentul DPA vor avea înțelesul prezentat în Acord. Clientul intră în acest
                  acord (DPA) în numele său propriu și, în măsura în care acest lucru este cerut pe baza Legilor de
                  Protecție a Datelor, în numele și reprezentând Filialele sale Autorizate (definite mai jos).</p>
                <p><strong>Părțile convin cele ce urmează:</strong></p>
                <h2>1. Definiții</h2>
                <p><strong>“Filială”</strong> înseamnă o entitate care Controlează, este Controlată de sau se află sub
                  Control comun cu o entitate, în mod direct sau indirect.</p>
                <p><strong>“Filială Autorizată”</strong> înseamnă oricare dintre Filiala (Filialele) Clientului, căreia
                  îi este permis să sau care este în alt fel beneficiara Serviciilor, conform Acordului.</p>
                <p><strong>“Control”</strong> înseamnă dreptul de proprietate, de vot sau alte drepturi asemănătoare,
                  reprezentând cincizeci la sută (50%) sau mai mult din drepturile totale astfel restante ale entității
                  respective. Termenul “Controlat” va fi interpretat în consecință.</p>
                <p><strong>“Controlor”</strong> înseamnă o entitate care stabilește scopurile și mijloacele prelucrării
                  Datelor cu caracter personal.</p>
                <p><strong>“Datele Clienților”</strong> înseamnă orice date pe care Sitebunker și/sau Filialele sale le
                  prelucrează în numele Clientului în cursul furnizării Serviciilor, în conformitate cu Acordul.</p>
                <p><strong>“Legile privind Protecția Datelor”</strong> înseamnă toate legile și regulamentele privind
                  protecția și confidențialitatea datelor aplicabile prelucrării Datelor cu caracter personal, în
                  conformitate cu Acordul, inclusiv, unde este cazul, Legea UE cu privire la Protecția Datelor.</p>
                <p><strong>“Legea UE privind Protecția Datelor”</strong> înseamnă (i) înainte de 25 mai 2018, Directiva
                  95/46/EC a Parlamentului și Consiliului European cu privire la protecția persoanelor, în legătură cu
                  prelucrarea datelor cu caracter personal și cu libera mișcare a acestor date (“Directiva”) și la data
                  de și după data de 25 mai 2018, Regulamentul 2016/679 al Parlamentului și Consiliului European cu
                  privire la protecția persoanelor fizice, în legătură cu prelucrarea datelor cu caracter personal și cu
                  libera mișcare a acestor date (Regulamentul General privind Protecția Datelor) (“GDPR”); și (ii)
                  Directiva 2002/58/EC privitoare la Datele cu caracter personal și la protecția confidențialității în
                  cadrul sectorului comunicărilor electronice și la implementările sale naționale aplicabile (în fiecare
                  caz, prevederile pot fi modificate sau înlocuite).</p>
                <p><strong>“Datele cu caracter personal”</strong> înseamnă orice Date ale clienților, legate de o
                  persoană fizică identificată sau identificabilă, în măsura în care respectivele informații sunt
                  protejate ca date cu caracter personal, în conformitate cu Legea privind Protecția datelor,
                  aplicabilă.</p>
                <p><strong>“Protecția confidențialității”</strong> înseamnă Cadrele EU-US și Swiss-US privind Protecția
                  Confidențialității, după cum sunt ele aplicate de Departamentul de Comerț al S.U.A.</p>
                <p><strong>“Principiile Protecției Confidențialității”</strong> înseamnă Principiile Cadrului privind
                  Protecția Confidențialității (conform completărilor Principiilor Suplimentare) cuprinse în Anexa II la
                  Decizia Comisiei Europene din data de 12 iulie 2016, în conformitate cu Directiva, ale căror detalii
                  poate fi găsite accesând <a href="www.privacyshield.gov/eu-us-framework"
                                              aria-invalid="true">www.privacyshield.gov/eu-us-framework</a>.</p>
                <p><strong>“Procesatorul”</strong> înseamnă o entitate care prelucrează Date cu caracter personal în
                  numele Controlorului.</p>
                <p><strong>“Prelucrarea”</strong> are înțelesul care i-a fost dat în GDPR, iar “prelucrare”,
                  “prelucrări” și “prelucrate” vor fi interpretate în consecință.</p>
                <p><strong>“Incident de securitate”</strong> înseamnă orice încălcare neautorizată sau ilegală a
                  securității, care duce la distrugerea, pierderea, modificarea, accidentale sau ilegale, la divulgarea
                  neautorizată a sau accesul la Datele cu caracter personal.</p>
                <p><strong>“Serviciile”</strong> înseamnă orice produs sau serviciu furnizat de Sitebunker Clientului,
                  în conformitate cu și în baza descrierilor mai detaliate din Acord.</p>
                <p><strong>“Sub-procesator”</strong> înseamnă orice Procesator angajat de Sitebunker sau de Filialele
                  sale să o asiste la îndeplinirea obligațiilor sale privitoare la furnizarea Serviciilor, în
                  conformitate cu Acordul sau cu prezentul DPA. Sub-procesatorii pot include terți sau orice altă
                  Filială Sitebunker.</p>
                <h2>2. Domeniul de aplicare și aplicabilitatea prezentului DPA</h2>
                <p><strong>2.1</strong> Prezentul DPA se aplică numai în cazul și în măsura în care Sitebunker
                  prelucrează Date cu caracter personal în numele Clientului, pe parcursul furnizării Serviciilor și
                  aceste Date cu caracter personal sunt supuse Legilor privind protecția datelor ale Uniunii Europene,
                  ale Zonei Economice Europene și/sau ale statelor lor membre, ale Elveției și/sau ale Regatului Unit.
                  Părțile convin să respecte termenii și condițiile din prezentul DPA în legătură cu astfel de Date cu
                  caracter Personal.</p>
                <p><strong>2.2 Rolul Părților.</strong> Dintre Sitebunker și Client, Clientul este Controlorul Datelor
                  cu caracter personal, iar Sitebunker va prelucra Datele cu caracter personal numai ca Procesator, în
                  numele Clientului. Nimic din Acord sau din prezentul DPA nu va împiedica Sitebunker să folosească sau
                  să distribuie orice date, pe care, Sitebunker le va colecta în alt mod și le va prelucra în mod
                  independent de utilizarea Serviciilor de către Client.</p>
                <p><strong>2.3 Obligațiile Clientului.</strong> Clientul convine să (i) își respecte obligațiile de
                  Controlor, în conformitate cu Legile privind Protecția Datelor, respectând prelucrarea Datelor cu
                  caracter personal și toate instrucțiunile de prelucrare pe care le emite către Sitebunker; și (ii)
                  este de acord că a informat și a obținut (sau va obține) toate aprobările și drepturile necesare în
                  conformitate cu Legile privind Protecția datelor pentru ca Sitebunker să prelucreze Datele cu caracter
                  personal și să furnizeze Serviciile, conform Acordului și prezentului DPA.</p>
                <p><strong>2.4 Prelucrarea Datelor de către Sitebunker.</strong> În calitatea de Procesator, Sitebunker
                  va prelucra Datele cu caracter personal exclusiv în următoarele scopuri: (i) prelucrarea în scopul
                  furnizării Serviciilor în conformitate cu Acordul; (ii) prelucrarea în scopul efectuării oricăror pași
                  necesari punerii în aplicare a Acordului; și (iii) pentru a se conforma oricăror alte instrucțiuni
                  rezonabile furnizate de Client, în măsura în care acestea respectă termenii prezentului Acord și numai
                  în conformitate cu instrucțiunile legale și documentate ale Clientului. Părțile convin că prezentul
                  DPA și Acordul stabilesc instrucțiunile complete și finale ale Clientului către Sitebunker în legătură
                  cu prelucrarea Datelor cu caracter personal, iar prelucrarea în afara domeniului de aplicare a acestor
                  instrucțiuni (dacă este cazul) va necesita acordul scris prealabil între Client și Sitebunker.</p>
                <p><strong>2.5 Tipul de Date.</strong> Sitebunker gestionează Datele Clientului furnizate de Client.
                  Astfel de Date ale Clientului pot conține categorii speciale de date, în funcție de cum sunt folosite
                  Serviciile de către Client. Datele Clientului pot fi supuse următoarelor activități de prelucrare: (i)
                  stocarea și alte tipuri de prelucrări, necesare pentru furnizarea, păstrarea și îmbunătățirea
                  Serviciilor furnizate Clientului; (ii) oferirea de suport tehnic și asistență către Client; și (iii)
                  divulgarea, conform cerințelor legale sau altor prevederi prezentate în Acord.</p>
                <p><strong>2.6 Datele Sitebunker.</strong> Fără a aduce vreun prejudiciu prevederilor Acordului
                  (inclusiv prezentului DPA), Clientul recunoaște că Sitebunker va avea dreptul de a folosi și de a
                  divulga date legate de și/sau obținute în legătură cu funcționarea, sprijinul și/sau utilizarea
                  Serviciilor în scopurile sale comerciale legitime, cum ar fi facturarea, gestionarea contului,
                  suportul tehnic, dezvoltarea produsului și vânzările sau promovarea. În măsura în care oricare astfel
                  de date sunt considerate date cu caracter personal conform Legilor privind Protecția Datelor,
                  Sitebunker este Controlorul unor astfel de date și va prelucra în consecință aceste date, respectând
                  Legile privind Protecția Datelor.</p>
                <h2>3. Sub-prelucrarea</h2>
                <p><strong>3.1 Sub-procesatori autorizați.</strong> Clientul este de acord că Sitebunker poate angaja
                  Sub-procesatori pentru prelucrarea Datelor cu caracter personal, în numele Clientului.
                  Sub-procesatorii angajați actualmente de Sitebunker și autorizați de Clienți sunt enumerați în Anexa
                  A.</p>
                <p><strong>3.2 Obligațiile Sub-procesatorilor.</strong> Sitebunker: (i) va semna un acord scris cu
                  Sub-procesatorii, impunând termenii privind protecția datelor, care le cer Sub-procesatorilor să
                  protejeze Datele cu caracter Personal, la standardul solicitat de Legile privind Protecția Datelor; și
                  (ii) își păstrează responsabilitatea pentru respectarea obligațiilor din prezentul DPA și pentru orice
                  act sau omisiune a Sub-procesatorului care determină Sitebunker să își încalce oricare dintre
                  obligații, în conformitate cu DPA.</p>
                <p><strong>3.3 Modificări privind Sub-procesatorii.</strong> Sitebunker își va informa Clienții, cu
                  suficient timp înainte, (informarea prin e-mail ar trebui să fie suficientă), dacă adaugă sau șterge
                  Sub-procesatori.</p>
                <p><strong>3.4 Obiecții privind Sub-procesatorii.</strong> Clientul poate obiecta în scris față de
                  numirea unui nou Sub-procesator de către Sitebunker, pe baza unor motive rezonabile legate de
                  protecția datelor, informând prompt Sitebunker, în scris, în termen de cinci (5) zile calendaristice
                  de la primirea notificării din partea Sitebunker, în conformitate cu Secțiunea 3.3. O astfel de
                  informare va explica motivele rezonabile ale obiecției. Într-o astfel de situație, părțile vor discuta
                  aceste îngrijorări, cu bună credință, cu scopul de a ajunge la o rezolvarea rezonabilă, din punct de
                  vedere comercial. Dacă acest lucru nu este posibil, oricare dintre părți poate să încheie solicite
                  încheierea furnizării Serviciilor aplicabile care nu pot fi furnizate de Sitebunker fără implicarea
                  noului Sub-procesator, care a fost contestat.</p>
                <h2>4. Securitatea</h2>
                <p><strong>4.1 Măsuri de securitate.</strong> Sitebunker va implementa și va păstra măsuri de securitate
                  tehnică și organizațională corespunzătoare, pentru a proteja Datele cu caracter personal în fața
                  Incidentelor de Securitate și pentru a păstra securitatea și confidențialitatea Datelor cu caracter
                  personal, în conformitate cu standardele de securitate ale Sitebunker, descrise în Anexa B (<strong>“Măsuri
                    de securitate”</strong>).</p>
                <p><strong>4.2 Confidențialitatea prelucrării.</strong> Sitebunker se va asigura că orice persoană care
                  este autorizată de Sitebunker să prelucreze Date cu caracter personal (inclusiv angajații, agenții sau
                  subcontractorii săi) va trebui să respecte obligația de confidențialitate corespunzătoare (fie că este
                  vorba de o sarcină contractuală sau statutară).</p>
                <p><strong>4.3 Răspunsul în cazul unui incident de securitate.</strong> În momentul în care vine la
                  cunoștință despre un Incident de Securitate, Sitebunker îl va informa pe Client, fără vreo întârziere
                  nejustificată, și îi va furniza informații în timp util cu privire la respectivul Incident de
                  Securitate, îndată ce acesta îi este adus la cunoștință Clientului sau în funcție de cerințele
                  rezonabile ale acestuia.</p>
                <p><strong>4.4 Actualizările Măsurilor de Securitate.</strong> Clientul recunoaște că Măsurile de
                  Securitate sunt supuse progreselor și dezvoltărilor tehnice și că Sitebunker poate modifica sau poate
                  actualiza Măsurile de Securitate, din când în când, cu condiția ca aceste actualizări sau modificări
                  să nu ducă la degradarea securității generale a Serviciilor achiziționate de către Client.</p>
                <h2>5. Rapoartele de securitate și Auditul</h2>
                <p><strong>5.1</strong> Sitebunker va păstra înregistrări ale standardelor sale de securitate. La
                  cererea scrisă a Clientului, Sitebunker va furniza (în mod confidențial) copii ale certificărilor
                  externe ISMS relevante, rezumate ale rapoartelor de audit și/sau alte documente solicitate în mod
                  rezonabil de către Client, pentru a verifica respectarea de către Sitebunker a prezentului DPA.
                  Sitebunker va oferi în continuare răspunsuri scrise (în mod confidențial) la toate solicitările
                  rezonabile de informații efectuate de către Client, inclusiv răspunsuri la chestionarele privind
                  securitatea informațiilor și la audit, pe care Clientul (în mod rezonabil) le consideră necesare
                  pentru confirmarea faptului că Sitebunker respectă prezentul DPA, în condițiile în care Clientul nu
                  își va exercita acest drept mai mult de o dată pe an.</p>
                <h2>6. Transferuri Internaționale</h2>
                <p><strong>6.1</strong> Locațiile de prelucrare. Sitebunker stochează și prelucrează Datele UE (definite
                  mai jos) în centre de date localizate pe teritoriul și în afara Uniunii Europene. Toate celelalte Date
                  ale Clienților pot fi transferate și prelucrate în România și oriunde în lume, acolo unde Clientul,
                  Filialele sale și/sau Sub-procesatorii săi păstrează operațiunile de prelucrare a datelor. Sitebunker
                  va implementa măsuri de protecție adecvate pentru protejarea datelor cu caracter personal, oriunde
                  sunt prelucrate aceste date, în conformitate cu cerințele Legilor privind Protecția datelor.</p>
                <p><strong>6.2</strong> Mecanismul de transfer: Fără a aduce atingere Secțiunii 6.1, în măsura în care
                  Sitebunker prelucrează sau transferă (direct sau prin transfer ulterior) Date cu caracter personal, în
                  baza prezentului DPA din Uniunea Europeană, din Zona Economică Europeană și/sau din statele lor membre
                  și Elveția (“Datele UE”) în sau către țări care nu asigură un nivel corespunzător de protecție a
                  datelor, în sensul Legilor aplicabile privind Protecția Datelor ale teritoriilor de mai sus, părțile
                  convin ca Sitebunker să fie considerată responsabilă pentru asigurarea unor măsuri de protecție
                  adecvate pentru aceste date, în virtutea certificării conformității sale cu Protecția
                  Confidențialității, iar Sitebunker va prelucra aceste date în conformitate cu Principiile Protecției
                  Confidențialității. Clientul autorizează, prin prezentul, orice transfer de Date UE către, sau accesul
                  la Datele UE din, astfel de destinații aflate în afara UE, supus oricăreia dintre aceste măsuri care
                  au fost adoptate.</p>
                <h2>7. Returnarea sau Ștergerea Datelor</h2>
                <p><strong>7.1</strong> În momentul dezactivării Serviciilor, toate Datele cu caracter personal vor fi
                  șterse, cu excepția cazului în care i se va solicita Sitebunker, conform cerințelor legii aplicabile,
                  să rețină unele sau toate Datele cu caracter personal sau Datele cu caracter personal pe care le-a
                  arhivat pe sistemele de back-up, pe care, Sitebunker le va izola în siguranță și le va proteja de
                  orice prelucrare ulterioară, cu excepția cazurilor în care legea îi cere să procedeze în mod contrar.
                </p>
                <h2>8. Cooperarea</h2>
                <p><strong>8.1</strong> În măsura în care Clientul nu poate accesa în mod independent Datele cu caracter
                  personal relevante, în cadrul Serviciilor, Sitebunker va lua în considerare (pe cheltuiala Clientului)
                  tipul de procesare, va oferi o cooperare rezonabilă, pentru asistarea Clientului cu ajutorul măsurilor
                  tehnice și organizaționale corespunzătoare, în limitele posibilităților, pentru a răspunde oricăror
                  cereri de la persoane sau de la autoritățile responsabile cu aplicarea protecției datelor, în legătură
                  cu prelucrarea Datelor cu caracter personal, în baza Acordului. În cazul în care orice astfel de
                  cerere este făcută direct către Sitebunker, Sitebunker nu va răspunde direct unei astfel de
                  comunicări, fără autorizarea prealabilă sin partea Clientului, decât dacă este obligat legal să facă
                  acest lucru. Dacă firmei Sitebunker i se solicită un răspuns la o astfel de cerere, Sitebunker îl va
                  informa pe Client cu promptitudine și îi va oferi o copie a cererii, excepție fiind cazul în care îi
                  este interzis prin lege să facă acest lucru.</p>
                <p><strong>8.2</strong> În măsura în care firmei Sitebunker i se cere acest lucru, în baza Legii
                  Protecției Datelor, Sitebunker va furniza (pe cheltuiala Clientului) informațiile rezonabile
                  solicitate cu privire la prelucrarea de către Sitebunker a Datelor cu caracter personal, în baza
                  Acordului, pentru a permite Clientului să efectueze evaluări ale impactului protecției datelor cu
                  caracter personal sau consultări prealabile cu autoritățile responsabile cu protecția datelor, conform
                  cerințelor legale.</p>
                <h2>9. Diverse</h2>
                <p><strong>9.1</strong> Cu excepția modificărilor efectuate prin prezentul DPA, Acordul rămâne
                  neschimbat și în totalitate în vigoare. Dacă există orice conflict între prezentul DPA și Acord,
                  prezentul DPA va prevala, în limitele respectivului conflict.</p>
                <p><strong>9.2</strong> Prezentul DPA este parte a și este încorporat în Acord, astfel încât,
                  referințele la “Acord”, din Acord, vor include prezentul DPA.</p>
                <p><strong>9.3</strong> În nici un caz, niciuna dintre părți nu va limita propria răspundere cu privire
                  la oricare dintre drepturile persoanelor privind de protecția datelor, în baza prezentului DPA sau în
                  alt fel.</p>
                <p><strong>9.4</strong> Prezentul DPA va fi reglementat și interpretat în conformitate cu prevederile
                  legii și ale jurisdicției aplicabile din Acord, dacă nu este altfel solicitat de Legile privitoare la
                  Protecția Datelor.</p>
                <h2>Anexa A – Lista Sub-procesatorilor Sitebunker</h2>
                <p>Disponibilă la cerere</p>
                <h2>Anexa B – Măsuri de Securitate</h2>
                <p>Disponibilă la cerere</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}
export default Gdpr
